import React from "react"
import Manager from "../components/manager";
import Control from "../controls/welcome";
import Theme from "../configs/theme";
import config from "../configs/config.json";

export default function WelcomePage() {
  return (
    <Manager name="welcome" title="Welcome">
      <Theme>
        <Control {...config}/>
      </Theme>
    </Manager>
  );
}


