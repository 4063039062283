import React, {Fragment, useEffect} from "react";
// import Mustache from "mustache";
import ejs from "ejs";
import queryString from "query-string";
import root from "window-or-global";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// function copyStr(src) {
//   let out = "";
//   for(let i = 0; i < src.length ; i++) {
//     out += String.fromCharCode(src.charCodeAt(i));
//   }
//   return out;
// }

// function unicodeEscape(str) {
//   for (var result = '', index = 0, charCode; !isNaN(charCode = str.charCodeAt(index++));) {
//     result += '\\u' + ('0000' + charCode.toString(16)).slice(-4);
//   }
//   return result;
// }

export default function WelcomePreview(props) {
  const {welcomeMessage, welcomeTitle, enableWelcomeRedirect, welcomeRedirectUrl, disableForm} = props;
  let search = ""
  if (root.location) {
    search = (`${root.location.search}` || "");
    console.log("search", root.location.search);
  }
  let parseQs = {
    userName: "",
    firstName: "",
  };
  if(search && search !== "") {
    parseQs = search.replace("?", "").split("&").reduce((o, e) => {
      const [k, v] = e.split("=");
      o[k] = v;
      return o;
    }, {})
  }

  //const parseQs = queryString.parse(search);
  console.log("parseQS", {parseQs, welcomeMessage});
  useEffect(() => {
    document.body.classList.toggle("loaded", true);
    document.body.classList.toggle("hide-loader", true);
  }, []);
  if(parseQs.userName === "") {
    return (<Fragment />);
  }
  if (!disableForm && enableWelcomeRedirect) {
    window.location =  ejs.render(welcomeRedirectUrl, parseQs);
    return (<Fragment />);
  }
  const welcomeMessageOutput = ejs.render(welcomeMessage || "", {
    userName: parseQs.userName || "",
    firstName: parseQs.firstName || "",
  });
  const welcomeTitleOutput = ejs.render(welcomeTitle || "", {
    userName: parseQs.userName || "",
    firstName: parseQs.firstName || "",
  })
  console.log("outputs", {welcomeTitleOutput, welcomeMessageOutput} )
  return (
    <>
      <div>
        <h2 className="welcome-title" dangerouslySetInnerHTML={{
          __html: welcomeTitleOutput,
        }} />
        <div dangerouslySetInnerHTML={{
          __html: welcomeMessageOutput,
        }} />
      </div>
    </>
  );
}
